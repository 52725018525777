import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./components/App";
import { createBrowserHistory } from "history";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css"

import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const history = createBrowserHistory();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Router>
      <ToastContainer />
      <App history={history} />
    </Router>
  </QueryClientProvider>,
  document.getElementById("root")
);
