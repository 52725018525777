import React from "react";
// import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import NavBar from "components/NavBar";
// import Footer from "components/Footer";
import { isAuthenticated } from "utils/authHandler";

/**
 * Renders the component if the user is authenticated
 *
 * @param {Component} Component
 *
 * @returns {JSX}
 */
const renderComponent = (Component) => (props) => {
  const isAuth = isAuthenticated();
  if (!isAuth) return (window.location.href = "/login");

  return (
    <>
      <NavBar isAuth={isAuth} />
      <Component {...props} />
      <br />
      {/* <Footer /> */}
    </>
  );
};

/**
 * This Component returns a new
 * route based on authenticated status
 *
 * @returns {JSX}
 */
const AuthenticatedRoute = (props) => {
  const { component: Component, ...rest } = props;
  // const checkisAuthenticated = isAuthenticated();

  // if (!checkisAuthenticated) {
  //   return <Redirect to="/login" />;
  // }
  // useEffect(() => {
  //   document && document.querySelector("body").classList.add("bg-white");
  // }, []);
  return (
    <>
      <Route {...rest} render={renderComponent(Component)} />
    </>
  );
};

export default AuthenticatedRoute;
