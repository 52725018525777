import React from "react";
import { Switch, Route } from "react-router-dom";
import AuthenticatedRoute from "./AuthenticatedRoute";
import { allRoutes } from "../routes";

const App = () => (
  <div>
    <Switch>
      {allRoutes.map((route, index) => {
        if (route?.isAuth) {
          return (
            <AuthenticatedRoute
              key={index}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          );
        } else {
          return (
            <Route
              key={index}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          );
        }
      })}
    </Switch>
  </div>
);

export default App;
