import React, { useEffect, useState } from "react";
import BurgerIcon from "../../assets/img/svg/bars.svg";
import clearLocalStorage from "utils/clearData";
import Logo from "../../assets/img/logo-dp.png";

const NavBar = ({ isAuth }) => {
  const [email, setEmail] = useState("");
  const handleLogOut = () => {
    clearLocalStorage();
    window.location.href = "/login";
  };

  useEffect(() => {
    const getUserEmail = localStorage.getItem("edaappemail");
    const getUserToken = localStorage.getItem("edaaccestoken");

    if (getUserEmail || getUserToken) {
      try {
        if (!atob(getUserToken) || getUserEmail !== atob(getUserToken)) {
          return handleLogOut();
        }
        setEmail(getUserEmail);
      } catch {
        handleLogOut();
      }
    } else {
      handleLogOut();
    }
  }, []);

  return (
    <header className="header-top">
      <nav className="navbar navbar-light">
        <div className="navbar-left">
          <a href="#?" className="sidebar-toggle">
            <img className="svg" src={BurgerIcon} alt="img" />
          </a>
          <a className="navbar-brand" href="#?">
            <a href="/">
              <img src={Logo} width="40px" alt="dp-logo" />
            </a>
            {/* <img className="dark" src="img/logo_dark.png" alt="svg" /> */}
            {/* <img className="light" src="img/logo_white.png" alt="img" /> */}
          </a>
        </div>

        <div className="navbar-right">
          <ul className="navbar-right__menu">
            <li className="nav-notification">
              <div className="dropdown-custom">
                <a href="#?" className="nav-item-toggle">
                  <span data-feather="bell"></span>
                </a>
                <div className="dropdown-wrapper">
                  <h2 className="dropdown-wrapper__title">
                    Notifications{" "}
                    <span className="badge-circle badge-warning ml-1">0</span>
                  </h2>
                </div>
              </div>
            </li>

            <li className="nav-flag-select">
              <div className="dropdown-custom">
                <a href="#?" className="nav-item-toggle">
                  <img src="img/flag.png" alt="" className="rounded-circle" />
                </a>
                <div className="dropdown-wrapper dropdown-wrapper--small">
                  <a href="#?">
                    <img src="img/eng.png" alt="" /> English
                  </a>
                </div>
              </div>
            </li>
            <li className="nav-author">
              <div className="dropdown-custom">
                <a href="#?" className="nav-item-toggle">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/7153/7153150.png"
                    alt=""
                    className="rounded-circle"
                  />
                </a>
                <div className="dropdown-wrapper">
                  <div className="nav-author__info">
                    <div className="author-img">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/7153/7153150.png"
                        alt=""
                        className="rounded-circle"
                      />
                    </div>
                    <div>
                      <h6>{email}</h6>
                    </div>
                  </div>
                  <div className="nav-author__options">
                    <ul>
                      <li>
                        <a href="#?">
                          <span data-feather="user"></span> Profile
                        </a>
                      </li>
                      <li>
                        <a href="#?">
                          <span data-feather="key"></span> Billing
                        </a>
                      </li>
                    </ul>
                    <a
                      href="#?"
                      className="nav-author__signout"
                      onClick={handleLogOut}
                    >
                      <span data-feather="log-out"></span> Sign Out
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div className="navbar-right__mobileAction d-md-none">
            <a href="#?" className="btn-author-action">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-more-vertical"
              >
                <circle cx="12" cy="12" r="1"></circle>
                <circle cx="12" cy="5" r="1"></circle>
                <circle cx="12" cy="19" r="1"></circle>
              </svg>
            </a>
          </div>
        </div>
      </nav>
      <div class="alert alert-info d-md-none d-xs-block" role="alert" style={{
        display: "block"
      }}>
        <div class="alert-content text-center">
          <p style={{
        fontSize: "12px"
      }}>View on tablet/desktop for better experience</p>
        </div>
      </div>
    </header>
  );
};

export default NavBar;
