import React from "react";
import Illus from "../../assets/img/illustration.png";
import DataAnalysis from "../../assets/img/data3.png";

const Main = () => {
  const loginLink = "#?";
  const getStartedLink = "#?";

  return (
    <>
      <div class="header-top-four">
        <div class="container-h-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="header-top-four-area-wrapper">
                <div class="social-area">
                  {/* <a href="#"><i class="fa-brands fa-twitter"></i></a> */}
                  <a href="#?">
                    <i class="fa-brands fa-linkedin-in"></i>
                  </a>
                </div>
                <p class="mid-disc">
                  <a href={getStartedLink} target="_blank" rel="noreferrer">
                    Get started
                  </a>
                  &nbsp; as we make data cleaning easier and intuitive
                </p>
                <div class="end-part">
                  {/* <a href="#">Privacy Policy</a> */}
                  <a href="#?">Contact: hello@dataplora.com</a>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option selected>EN</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rts-sectio-gap banner-four-bg rts-section-gapBottom">
        <div class="header-main-wrapper four-header header--sticky">
          <div class="container-h-4">
            <div class="row">
              <div class="col-lg-12">
                <div
                  class="main-4-header-wrapper"
                  style={{
                    padding: "0px",
                  }}
                >
                  <a href="." class="logo">
                    <img
                      src="assets/images/logo-dp.png"
                      width="40px"
                      alt="logo"
                      loading="lazy"
                    />
                  </a>
                  <div class="nav-area-main d-xl-block d-lg-block d-md-none d-sm-none d-none">
                    <nav>
                      <ul>
                        <li class="">
                          <a class="links-main" href="#?">
                            Home
                          </a>
                        </li>
                        <li>
                          <a class="links-main" href="#?">
                            About
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div class="button-area">
                    <a
                      href={loginLink}
                      class="rts-btn btn-lonly mr--30"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Sign in
                    </a>
                    <a
                      href={getStartedLink}
                      class="rts-btn btn-blur"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Get Started
                    </a>
                  </div>
                  <div
                    class="menu-btn d-xl-none d-lg-none d-md-block d-sm-block"
                    id="menu-btn"
                  >
                    <svg
                      width="20"
                      height="16"
                      viewBox="0 0 20 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect y="14" width="20" height="2" fill="#1F1F25"></rect>
                      <rect y="7" width="20" height="2" fill="#1F1F25"></rect>
                      <rect width="20" height="2" fill="#1F1F25"></rect>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="container pt--80 pt_md--60 pt_sm--50"
          style={{
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <div class="row align-items-start">
            <div class="col-lg-7 col-md-12 col-sm-12 col-12 order-xl-1 order-lg-1 order-md-2 order-sm-2 order-2">
              <div class="banner-four-content mt--40 mt_md--50 mt_sm--50">
                <h1 class="title">Turn data into sense in less time</h1>
                <p class="disc">
                  Save Time and Resources. Automate time-consuming data cleaning
                  tasks and focus on deriving value from your data.
                  <br />
                </p>
                <div class="button-area-main">
                  <a
                    href={getStartedLink}
                    target="_blank"
                    rel="noreferrer"
                    class="rts-btn btn-primary"
                  >
                    Get Started
                  </a>
                  {/* <a class="btn-only" href="#">Book a Demo <i class="fa-regular fa-arrow-right"></i></a> */}
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-md-12 col-sm-12 col-12 order-xl-2 order-lg-2 order-md-1 order-sm-1 order-1 mt--20">
              <div class="thumbnail-banner-right text-lg-center">
                <img src={Illus} alt="banenr" loading="lazy" width="60%" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rts-service-aarea-four rts-section-gap">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="title-main-center-4">
                <h2 class="title">
                  Your Trusted <br /> Data Cleaning Platform
                </h2>
                <p class="disc">
                 Clean Data, Clear Insights. Automate Your Data Cleaning Process.
                </p>
              </div>
            </div>
          </div>
          <div class="row g-5 mt--20">
            <div class="col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="single-service-area-4">
                <div class="icon">
                  <img
                    src={DataAnalysis}
                    alt="thumbnail"
                    loading="lazy"
                    width="30px"
                  />
                </div>
                <h5 class="title">Usability</h5>
                <p class="disc">
                  A user-friendly and intiuitive interface <br />
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="single-service-area-4">
                <div class="icon">
                  <img
                    src={DataAnalysis}
                    alt="thumbnail"
                    loading="lazy"
                    width="30px"
                  />
                </div>
                <h5 class="title">Insightful</h5>
                <p class="disc">
                  Effortlessly clean, analyze, and understand your data's
                  story
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="single-service-area-4">
                <div class="icon">
                  <img
                    src={DataAnalysis}
                    alt="thumbnail"
                    loading="lazy"
                    width="30px"
                  />
                </div>
                <h5 class="title">Decision Making</h5>
                <p class="disc">
                  Enabling informed decision-making and strategic planning.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rts-footer-area footer-two four footer-mid bg-footer">
        <div class="max-con border-copy">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="copy-right-two four">
                  <p>
                    <a href=".">DataPlora</a> 2024. All Rights Reserved.
                  </p>
                  <div class="menu">
                    <ul>
                      <li>
                        <a href="#?">Contact: hello@dataplora.com</a>
                      </li>
                      {/* <li><a href="#">Privacy Policy</a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="anywhere-home" class></div>
      <div id="side-bar" class="side-bar header-two">
        <button class="close-icon-menu">
          <i class="far fa-times"></i>
        </button>

        <div class="mobile-menu-main">
          <nav class="nav-main mainmenu-nav mt--30">
            <ul class="mainmenu metismenu" id="mobile-menu-active">
              <li class="parent">
                <a
                  href="https://app.dataplora.com"
                  target="_blank"
                  rel="noreferrer"
                  class="main"
                >
                  Get Started
                </a>
              </li>
              <li class="parent">
                <a
                  href={loginLink}
                  target="_blank"
                  rel="noreferrer"
                  class="main"
                >
                  Login
                </a>
              </li>
            </ul>
          </nav>

          <div class="rts-social-style-one pl--20 mt--30">
            <ul>
              <li>
                <a href="#?">
                  <i class="fa-brands fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#?">
                  <i class="fa-brands fa-linkedin-in"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
