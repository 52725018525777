import { Route } from "react-router-dom";
import Main from "../pages/main";

export const landingRoutes = [
  {
    type: Route,
    path: "/",
    component: Main,
    exact: true,
  },
  {
    type: Route,
    path: "/home",
    component: Main,
    exact: true,
  }
];
