import jwtDecode from "jwt-decode";
import clearLocalStorage from "./clearData";
import { TOKEN_NAME } from './constants';

export const authHandler = (key) => {
  const token = localStorage?.getItem(TOKEN_NAME);
  if (!token && !key) {
    clearLocalStorage();
    window.location.href = "/login";
  }
  if (key) {
    tokenHandler(key);
  }

  if (token) {
    tokenHandler(token);
  }
};

export const isAuthenticated = () => {
  const token = getToken();
  if (token) {
    return true;
  } else {
    return false;
  }
};

const tokenHandler = (token) => {
  try {
    const decode = jwtDecode(token);
    if (decode) {
      const checkTokenExpiryDate = decode.exp - Math.floor(Date.now() / 1000);

      if (checkTokenExpiryDate <= 0) {
        window.location.href = "/login";
      }

      localStorage.setItem(TOKEN_NAME, token);
    }
  } catch (e) {
    window.location.href = "/login";
  }
};

export const userProfile = () => {
  const name = localStorage.getItem("edname");
  return name;
};

export const logOut = () => {
  clearLocalStorage();
  window.location.href = "/login";
};

export const getToken = () => {
  const token = localStorage.getItem(TOKEN_NAME);
  if (token) {
    return token;
  }
  return null;
};

export const decodeToken = () => {
  if (getToken()) {
    const decoded = jwtDecode(getToken());
    if (decoded) {
      return decoded;
    } else {
      clearLocalStorage();
      window.location.href = "/login";
    }
  } else {
    clearLocalStorage();
    window.location.href = "/login";
  }
};

export const getCredit = () => {
  const credit = localStorage.getItem("skeelCredit");
  if (credit) {
    const decode = atob(credit);
    if (decode) {
      return decode;
    } else {
      clearLocalStorage();
      window.location.href = "/login";
    }
  } else {
    clearLocalStorage();
    window.location.href = "/login";
  }
};
