import { Route } from "react-router-dom";
import NotFound from "../pages/shared/NotFound";
// import Dashboard from "pages/dashboard";
// import PerkBox from "pages/perkbox/PerkBox";

export const authRoutes = [
  // {
  //   type: Route,
  //   path: "/perkbox",
  //   component: PerkBox,
  //   exact: true,
  //   isAuth: true,
  // },
  {
    type: Route,
    path: "*",
    component: NotFound,
  },
];
